.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.infotechno-video-bg1 {
  background-image: url(../public/assets/images/bg/home-infotechno-video-intro-bg.jpg);
  background-size: cover;
  background-repeat: no-repeat;
}

.megamenu--mega>li>ul>li>a {
  color: #908c8c !important;
}

.container-fluid .megamenu--mega {
  padding: 35px 35px 30px;
}


@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.menu-font-weight {
  font-weight: 500 !important;
  font-size: 17px !important;
}

.section-space--ptb_60 {
  padding-top: 30px;
  padding-bottom: 30px;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}


.about-menu-wrap {
    background: linear-gradient(135deg, #fff 46%, #ffa3000d 56%, #e6edf2 83%, #85dee14d 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff',endColorstr='#ededf4',GradientType=1);
    box-shadow: 0 2px 15px rgb(0 0 0/3%);
    max-height: calc(100vh - 120px);
    overflow-y: auto
}

